@use "sass:map";

body {
  color: $body-color;
  min-width: 280px;
  overflow-y: scroll;
}

audio {
  // HACK: Avoid cropped focus outlines on Chrome and remove extra spacing.
  margin: -3px;
  padding: 3px;
  width: 100%;
}

.soundtrack-carousel {
  background-color: #14193b;

  @media (min-width: 1200px) {
    img {
      height: 420px;
    }
  }

  @media (min-width: 768px) {
    img {
      height: 524px;
    }
  }
}

.home-howto {
  background-image: linear-gradient(
    to right,
    map.get($mixopia-primary, "second-tint"),
    map.get($mixopia-primary, "third-shade")
  );
  padding-block-start: 1em;
  padding-block-end: 1em;
  text-align: center;
  h1 {
    color: white;
  }
  h2 {
    font-family: $headings-font-family;
    font-size: 1.8rem;
    color: map.get($mixopia-primary, "fourth-tint");
  }
  p {
    color: white;
    font-size: 0.88rem;
  }
}

.list-group {
  margin-bottom: 2rem;

  .list-group-item-heading {
    span {
      vertical-align: middle;
    }

    .badge-secondary {
      margin-left: 0.5rem;
      vertical-align: middle;
    }
  }

  .list-group-item-action {
    color: $primary;
  }

  .list-group-item-action:active {
    color: $primary;
    background-color: #e7eaee;
  }

  .list-group-item {
    border-color: $primary;
    border-radius: $btn-border-radius;
    border-width: thin;
    margin: 0.1rem;
    padding: 0.5rem;
    span + .badge-pill {
      margin-left: 0.5rem;
    }
  }
}

.footer {
  color: $footer-color;
  font-size: 0.76rem;
  margin-top: 1rem;
  line-height: 2rem;
  position: relative;
  bottom: 0;
}
