@use "sass:map";

@mixin generate-color-schemes {
  @each $color-name, $value in $mixopia-primary {
    .color-scheme-#{$color-name} {
      $base-color: map.get($mixopia-primary, $color-name);
      $contrast-color: color-yiq($secondary);
      background-color: $base-color;
      color: $contrast-color;
    }
  }
}

// Generate a list of CSS classes for applying the primary colors to elements.
@include generate-color-schemes;
