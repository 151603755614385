// Load external fonts.
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Open+Sans:wght@300;400;700&display=swap');

// Bootstrap default variables, styles and overrides.
// NOTE: The import order is important.
@import "./styles/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./styles/overrides/buttons";
@import "./styles/overrides/button-theme.scss";
@import "./styles/overrides/button-tertiary.scss";
@import "./styles/overrides/other";

// Other styles.
@import "./styles/color-schemes";
@import "./styles/components";
@import "./styles/fonts";
@import "./styles/temporary";

