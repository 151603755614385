@use "sass:map";

$theme-color-list: 
("background": #D33E1E, "color": #ffffff)
("background": #5A5AA8, "color": #ffffff)
("background": #F47721, "color": #ffffff)
("background": #42296E, "color": #ffffff)
("background": #16193C, "color": #ffffff)
("background": #256767, "color": #ffffff)
("background": #F8F292, "color": #171C4E)
("background": #4FB2A4, "color": #ffffff)
("background": #FCD757, "color": #171C4E)
("background": #86C1B7, "color": #171C4E)
("background": #F7BE5A, "color": #171C4E)
("background": #E781B4, "color": #171C4E)
("background": #1B3E71, "color": #ffffff)
("background": #E94D9B, "color": #ffffff)
("background": #1D4F91, "color": #ffffff)
("background": #9D1E5D, "color": #ffffff)
("background": #2766AE, "color": #ffffff)
("background": #F7954C, "color": #ffffff);

@each $btn-theme-color in $theme-color-list {
  $i: index($theme-color-list, $btn-theme-color);

  .btn-variant.btn-theme:nth-child(18n+#{$i}) {
    $theme-color: map.get($btn-theme-color, "background");
    $theme-text-color: map.get($btn-theme-color, "color");
    
    background-color: $theme-color;
    border-color: $theme-color;
    border-radius: $btn-border-radius * 2;
    color: $theme-text-color;
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled).focus,
    &:not(:disabled):not(.disabled):hover {
      border-color: $primary;
      border-image-slice: initial;
      border-image-source: initial;
      box-shadow: none !important;
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: $theme-color;
      border-color: $primary;
      border-image-slice: initial;
      border-image-source: initial;
      box-shadow: none !important;
    }
  
    &.disabled,
    &:disabled {
      border-image: none;
      background-image: none;
      background-color: $mix-btn-disabled-color;
      border-color: $mix-btn-disabled-color;
      color: $mix-btn-disabled-contrast;
    }
  }
}