@use "sass:map";

/**
 * Use a linear-gradient on the border-box.
 * NOTE: For proper linear-gradient on border-image, 'border-image-slice: 1' is required, but it doesn't work with border-radius.
 */
@mixin border-gradient($background-left, $background-right, $border-left, $border-right) {
  background-clip: padding-box, border-box;
  background-color: $background-left;
  background-image: linear-gradient($background-left, $background-right), linear-gradient(to right, $border-left, $border-right);
  background-origin: border-box;
  border-color: transparent;
}

.btn-primary {
  background-image: linear-gradient(to right, $secondary, $mix-gradient-end);
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, $secondary, $mix-gradient-end);

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled).focus,
  &:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(to right, $secondary, $mix-gradient-end);
    border-color: $primary;
    border-image-slice: initial;
    border-image-source: initial;
    box-shadow: none !important;
    color: $primary;
  }
  
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $primary;
    border-image-slice: initial;
    border-image-source: initial;
    color: $primary;
  }

  &.disabled,
  &:disabled {
    border-image: none;
    background-image: none;
    background-color: $mix-btn-disabled-color;
    border-color: $mix-btn-disabled-color;
    color: $mix-btn-disabled-contrast;
  }
}

/**
* Note: We do not want to apply the secondary style for 'btn-variant'.
* This is a workaround for reactstrap button, who always add the class .btn-secondary || .btn-primary.
*  <Button />                => outputs "btn btn-secondary"
*  <Button color="primary"/> => outputs "btn btn-primary"
*  <Button color="special"/> => outputs "btn btn-secondary"
*  <Button className="btn-variant btn-tertiary" /> => outputs "btn-variant btn-tertiary btn btn-secondary"
*
*  All button styles different from primary|secondary will need to include css class '.btn-variant' for extensibility.
**/
.btn-secondary:not(.btn-variant) {
  $contrast-color: color-yiq($secondary);
  @include border-gradient($contrast-color, $contrast-color, $secondary, $mix-gradient-end);
  color: $secondary;

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled).focus,
  &:not(:disabled):not(.disabled):hover {
    border-color: $primary;
    color: $primary;
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $primary;
    color: $primary;
  }

  &.disabled,
  &:disabled {
    border-image: none;
    background-image: none;
    background-color: $mix-btn-disabled-contrast;
    border-color: $mix-btn-disabled-color;
    color: $mix-btn-disabled-color;
  }
}

.btn-collapse {
  background-color: white;
  border: none;
  background-image: none;
  border-image: none;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  color: $primary;

  &:focus {
    outline: none;
  }
}

.btn-player {
  border: none;
  background-color: transparent;
  border-image: none;
  box-shadow: none;
  outline: none;
  color: $primary;
  font-size: small;

  &:focus {
    outline: none;
  }
}

.btn-social {  
  $spacing: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 291pt;

  .btn {
    text-align: left;
    margin: $spacing;
    img {
      height: 25px;
      padding-right: $spacing;
    }
  }
}

