@use "sass:map";

.btn-variant.btn-tertiary {
    $tertiary-color: map.get($mixopia-neutral , 'first' );
  
    background-color: $tertiary-color;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    color: $primary;
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: $tertiary-color;
      border: none;
      box-shadow: none;
      color: $primary;
    }
  
    &.disabled,
    &:disabled {
      background-image: none;
      background-color: $mix-btn-disabled-contrast;
      border-image: none;
      color: $mix-btn-disabled-color;
    }
  }