@use "sass:map";

.app-page {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.app-layout {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
  // Add some spacing between sibling elements.
  .row + .row,
  .card + .card {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.app-actions-wrapper {
  $spacing: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // Remove outer-spacing added by buttons.
  margin-left: -$spacing;
  margin-right: -$spacing;
  margin-top: $spacing * 2;

  .btn {
    min-width: 8rem;
    flex-grow: 1;
    flex-shrink: 1;
    margin: $spacing;
  }
}

.app-actions-container {
  display: flex;
  justify-content: center;
}

.app-actions-wrapper.app-actions-wrapper--stacked-layout {
  flex-direction: column;
  flex-grow: 1;
  max-width: 291pt;
}

.app-actions-wrapper.app-actions-wrapper--tiles-layout {
  flex-direction: row;
  flex-wrap: wrap;

  .btn {
    flex-basis: 8rem;
    height: 4.5rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.app-narrow-container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.app-narrow-row {
  hr:last-child {
    margin-bottom: 0;
  }
}

.app-image-content {
  position: relative;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
    width: auto;
  }

  span {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.app-progress-status {
  align-items: center;
  display: flex;
  justify-content: center;

  .progress {
    flex-basis: 80px;
  }
}

.app-media-children {
  align-items: center;
  display: flex;
  justify-content: center;
}

.app-track-details-highlighted {
  h6,
  p {
    color: inherit;
  }

  .app-track-details,
  audio {
    margin: 0 !important;
  }
}

.app-explore-page {
  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  hr {
    width: 50%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.event-link {
  overflow-wrap: break-word;
  font-size: 0.88rem;
  max-width: 256px;
}
