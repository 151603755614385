html {
  font-family: $font-family-base;
  font-size: 17px;
}

h1 {
  font-family: $headings-font-family;
  font-size: 1.23rem;
  margin-bottom: 2rem;
}

h2 {
  font-family: $font-family-base;
  font-size: 0.88rem;
}

h3 {
  font-family: $font-family-base;
  font-size: 0.76rem;
}

h4 {
  font-family: $font-family-base;
  font-size: 0.88rem;
}

h5 {
  font-family: $font-family-base;
  font-weight: bold;
  font-size: 1rem;
}

label {
  font-weight: bold;
  font-size: 0.88rem;
}

.body-copy-2 {
  font-size: 0.88rem;
}

.body-copy-3 {
  font-size: 0.76rem;
}

.fw-bold {
  font-weight: bold;
}

.fw-light {
  font-weight: lighter;
}

.alert-header {
  font-weight: bold;
  font-family: $headings-font-family;
  font-size: 1.7rem;
}

.alert-body-copy-bold {
  font-weight: bold;
  font-size: 1.2rem;
}