@use "sass:map";

$mixopia-neutral: (
    first: #ffffff,
    second: #e4e2d2,
    third: #000000,
);
$mixopia-primary: (
    first: #f47721,
    first-tint: #f7954c,
    first-shade: #d33e1e,
    second: #42296e,
    second-tint: #5a5aa8,
    second-shade: #16193c,
    third: #e94d9b,
    third-tint: #e781b4,
    third-shade: #9d1e5d,
    fourth: #4fb2a4,
    fourth-tint: #86c1b7,
    fourth-shade: #256767,
);
$mixopia-secondary: (
    first: #f47721,
    first-tint: #f8f292,
    first-shade: #f7be5a,
    second: #42296e,
    second-tint: #2766ae,
    second-shade: #1b3e71,
);
$mixopia-other: (
    gradient: #a6a8ab,
    inactive: #bbbdbf,
    inactive-contrast: #ffffff,
    error: #ec1c24,
);

// Custom variables:
$mix-gradient-end: map.get($mixopia-primary, "third");

// Override bootstrap variables:
$body-color: map.get($mixopia-primary, "second");
$primary: map.get($mixopia-primary, "second");
$secondary: map.get($mixopia-primary, "first");

$hr-border-color: map.get($mixopia-other, "inactive");

$font-family-base: 'Open Sans', 'Noto Sans', sans-serif;
$headings-font-family: 'Merriweather', 'Noto Serif', serif;
$headings-font-weight: 700;
$headings-color: map.get($mixopia-primary, "second");

$btn-disabled-opacity: 1;
$mix-btn-disabled-color: map.get($mixopia-other, "inactive");
$mix-btn-disabled-contrast: map.get($mixopia-other, "inactive-contrast");
$btn-border-width: 2px;
$btn-border-radius: 4px;

$progress-border-radius: 5px;
$progress-bg: #ffffff;
$progress-bar-bg: map.get($mixopia-primary, "second-tint");
$mix-progress-bar-border: map.get($mixopia-primary, "second");

$success: map.get($mixopia-primary, "fourth");
$danger: map.get($mixopia-other, "error");

$footer-color: #5e6d79;
$input-border-color: #5e6d79;
$input-placeholder-color: #9aa7b2;
$h6-font-size: 0.875rem;
$border-radius: 0;
